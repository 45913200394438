import {
    DateFilterOperator,
    mapDisplayOperator,
} from '../../../components/AdvancedSearch/SearchFiltersOverlayList/helpers';
import { IAdvancedSearchPageSearchFilter } from './Page';

export const formFiltersPayload = (filters: IAdvancedSearchPageSearchFilter[]) => {
    const filtersPayload = {};

    filters.map((filter) => {
        if (filter.filterLabel === 'Type') {
            if (!filtersPayload['type']) {
                filtersPayload['type'] = [];
            }
            filtersPayload['type'].push(filter.value as string);
        } else if (filter.filterLabel.includes('Date')) {
            if (filter.value) {
                filtersPayload[`clauses.${filter.filterLabel}.value`] = filter.value;
            } else {
                filtersPayload[`clauses.${filter.filterLabel}`] = { exists: true };
            }
        } else if (filter.filterLabel === 'Tag') {
            // Tag added when the user is composing the query
            filtersPayload[`tags.${filter.value}`] = { exists: true };
        } else if (filter.filterLabel.startsWith('tags.')) {
            // Tag loaded from a saved query
            filtersPayload[filter.filterLabel] = { exists: true };
        } else {
            if (filter.value) {
                filtersPayload[`clauses.${filter.filterLabel}.text`] = filter.value;
            } else {
                filtersPayload[`clauses.${filter.filterLabel}`] = { exists: true };
            }
        }
        return filter;
    });

    return filtersPayload;
};

export const formQueryText = (queryText: string, exactMatchesOnly?: boolean) => {
    if (exactMatchesOnly) {
        return `\"${queryText}\"`;
    }

    return queryText;
};

export function getSelectorValue(clause) {
    if (!clause.value) return DateFilterOperator.IS_BEFORE;

    const keys = Object.keys(clause.value);
    if (keys.length === 2) {
        if (clause.value[keys[0]] === clause.value[keys[1]]) return DateFilterOperator.IS_EQUAL;

        return DateFilterOperator.IS_BETWEEN;
    }

    return mapDisplayOperator(keys[0]);
}

export const queryStr = (searchText: string, filtersAdded: IAdvancedSearchPageSearchFilter[]) => {
    let queryStr = '';

    if (searchText) {
        queryStr += `query=${searchText}`;
    }

    if (!filtersAdded.length) {
        return queryStr;
    }

    queryStr += `&filters=`;
    queryStr += filtersAdded
        .map((filter) => {
            if (!filter.value) return `${filter.filterLabel}=`;

            if (filter.filterLabel.includes('Date')) {
                const operatorKeys = Object.keys(filter.value);

                //means filter is either date range or equal operator
                if (operatorKeys.length === 2) {
                    const from = filter.value['gte'];
                    const to = filter.value['lte'];
                    return `${filter.filterLabel}=gte.${from}~lte.${to}`;
                }

                const operator = Object.keys(filter.value)[0];
                const dateValue = filter.value[operator];
                return `${filter.filterLabel}=${operator}.${dateValue}`;
            }

            return `${filter.filterLabel}=${filter.value}`;
        })
        .join(',');

    return queryStr;
};

export function getFilterParams(searchParamsFilters): IAdvancedSearchPageSearchFilter[] {
    const filters: IAdvancedSearchPageSearchFilter[] = searchParamsFilters.map(
        (filterStr: string) => {
            const filter = filterStr.split('=');
            const filterLabel = filter[0];
            let value = filter[1] !== 'null' ? filter[1] : ('' as any);
            let display = '';

            if (value) {
                if (filterLabel === 'Type') {
                    display = `is ${value}`;
                } else if (filterLabel.includes('Date')) {
                    if (value.includes('~')) {
                        const dates = value.split('~');

                        const dateObjVal1 = dates[0].split('.');
                        const operator1 = dateObjVal1[0];
                        const dateValue1 = dateObjVal1[1];

                        const dateObjVal2 = dates[1].split('.');
                        const operator2 = dateObjVal2[0];
                        const dateValue2 = dateObjVal2[1];

                        if (dateValue1 === dateValue2) {
                            display = `${DateFilterOperator.IS_EQUAL} ${dateValue1}`;
                        } else {
                            display = `${DateFilterOperator.IS_BETWEEN} ${dateValue1} and ${dateValue2}`;
                        }

                        value = {
                            [operator1]: dateValue1,
                            [operator2]: dateValue2,
                        };
                    } else {
                        const dateObjVal = value.split('.');
                        const operator = dateObjVal[0];
                        const dateValue = dateObjVal[1];
                        display = `${mapDisplayOperator(operator)} ${dateValue}`;
                        value = {
                            [operator]: dateValue,
                        };
                    }
                } else {
                    display = `has ${value}`;
                }
            }

            return {
                filterLabel,
                value,
                display,
            };
        }
    );

    return filters;
}
